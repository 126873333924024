function getDenseStylesheetId() {
  return 'denseStylesheet';
}

function getExistingDenseStylesheet(mwcElement: any): HTMLStyleElement | null {
  return mwcElement.shadowRoot.querySelector(`#${getDenseStylesheetId()}`);
}

export function applyDenseStylesheet(mwcElement: any) {
  // Hack to allow "dense" styling of the dropdown's textfield
  const existingStylesheet = getExistingDenseStylesheet(mwcElement)
  if (existingStylesheet) {
    return enableStylesheet(existingStylesheet);
  }

  const stylesheet = document.createElement('style');
  stylesheet.id = getDenseStylesheetId();

  const css = `
      .mdc-text-field {
        height: 40px !important;

        /* This ensures that the textinput in filled dropdowns fits */
        align-items: flex-end !important;
      }

      .mdc-text-field--outlined .mdc-floating-label--float-above {
        /* Adjust position of the label for outlined dropdowns */
        transform: translateY(-29.5px) !important;
      }
    `;

  stylesheet.appendChild(document.createTextNode(css));

  mwcElement.shadowRoot.appendChild(stylesheet);
}

function enableStylesheet(stylesheet: HTMLStyleElement) {
  stylesheet.removeAttribute('media');
}

function disableStylesheet(stylesheet: HTMLStyleElement) {
  stylesheet.setAttribute('media', 'max-width: 1px');
}

export function disableDenseStylesheet(mwcElement: any) {
  const existingStylesheet = getExistingDenseStylesheet(mwcElement);
  if (!existingStylesheet) {
    return;
  }

  disableStylesheet(existingStylesheet);
}
