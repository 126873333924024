import {html, css, LitElement, PropertyValues} from 'lit';
import {property} from 'lit/decorators.js';
import '@material/mwc-list/mwc-list.js';
import '@material/mwc-list/mwc-list-item.js';
import '@material/mwc-menu/mwc-menu.js';
import {CbarTextfield} from "@cbar/cbar-textfield";

export type Item = {
  text: string;
  value: string;
  image?: string;
  [key: string]: any;
};

export class CbarAutocomplete extends CbarTextfield {
  static styles = [
    ...CbarTextfield.styles,
    css`
      :host {
        display: inline-flex;
        position: relative;
      }

      cbar-textfield {
        width: 100%;
      }
    `
  ];

  @property({type: Boolean, reflect: true})
  open = false;

  // The element to anchor the dropdown to. Automatically set.
  @property({type: Object})
  anchor: any;

  // An array of all possible suggestions
  @property({type: Array})
  datasource: Item[] = [];

  // The currently suggested items
  @property({type: Array})
  suggestions: Item[] = [];

  @property({type: Number})
  maxSuggestions = 10;

  @property({type: Number})
  minLength = 0;

  @property({type: String})
  placeholder = '';

  @property({type: Boolean})
  dense = false;

  @property({type: Array})
  searchFields = ['text'];

  @property({type: Boolean})
  fixedMenuPosition = false;

  @property({type: Boolean})
  hideSearch = false;

  private pauseOpen = false;

  @property({type: Function})
  renderSuggestion = (suggestion: Item) => {
    return html`
      <mwc-list-item value=${suggestion.value} graphic="${suggestion.image ? "avatar" : "null"}">
        ${suggestion.image ? html`
          <img slot="graphic" src="${suggestion.image}">
        ` : html``}
        ${suggestion.text}
      </mwc-list-item>
    `;
  };

  private currentlyFocusedItemIndex = -1;

  connectedCallback() {
    super.connectedCallback();

    this.addEventListener('keyup', this.onKeyUp.bind(this));

    this.addEventListener('focus', this.onFocus);
    this.addEventListener('blur', this.onBlur);
    this.addEventListener('input', this.onInput);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.removeEventListener('focus', this.onFocus);
    this.removeEventListener('blur', this.onBlur);
    this.removeEventListener('input', this.onInput);
  }

  onFocus() {
    // On focus, open the suggestions
    this.open = this.shouldOpen;
  }

  onBlur() {
    this.open = false;
  }

  public firstUpdated() {
    super.firstUpdated();

    this.anchor = this.textfield;

    if (this.autoValidate && this.value) {
      this.reportValidity();
    }

    // Disable autocomplete
    this.formElement.autocomplete = 'off';
  }

  public updated(changedProperties: PropertyValues) {
    super.updated(changedProperties);

    const shouldCalculateSuggestions =
      changedProperties.has('value') ||
      changedProperties.has('datasource') ||
      changedProperties.has('searchFields');

    if (shouldCalculateSuggestions) {
      this.calculateSuggestions();
    }

    if (changedProperties.has('minLength')) {
      if (!this.meetsMinLength) {
        // Close when minLength is not met
        this.open = false;
      }
    }

    if (changedProperties.has('value')) {
      this.dispatchEvent(new CustomEvent('value-changed', {
        composed: true,
        bubbles: true,
        detail: {
          value: this.value,
        },
      }));
    }
  }

  get textfield() {
    return this.shadowRoot!.querySelector('.mdc-text-field');
  }

  get isFocused() {
    const activeElement = document.activeElement as HTMLElement | LitElement;

    return activeElement === this
      || this.shadowRoot?.activeElement !== null;
  }

  get queryLength() {
    return this.value ? this.value.length : 0;
  }

  get meetsMinLength() {
    return this.queryLength >= this.minLength;
  }

  get shouldOpen() {
    return !this.pauseOpen &&
      this.isFocused &&
      this.meetsMinLength;
  }

  get menu(): any {
    return this.shadowRoot!.querySelector('mwc-menu');
  }

  calculateSuggestions() {
    if (!this.datasource) {
      this.datasource = [];
    }

    this.suggestions = this.datasource.filter(item =>
      this.searchFields.some(field =>
        item[field]?.toLowerCase().indexOf(this.value.toLowerCase()) > -1
      )
    ).slice(0, this.maxSuggestions);

    this.unfocusCurrentlySelectedItem();
    this.currentlyFocusedItemIndex = -1;

    this.open = this.shouldOpen;
  }

  onOpened(e: CustomEvent) {
    this.focus();
  }

  onInput(e: any) {
    const target = e.target as HTMLInputElement;

    this.value = target.value;
  }

  onSelected(e: CustomEvent) {
    const {index} = e.detail;
    if (index < 0) {
      return;
    }

    requestAnimationFrame(() => {
      // We do this in an animation frame because otherwise
      // the cursor would be placed at the start of the textfield
      this.selectItem(index);
    });
  }

  pauseOpening(timeout = 500) {
    this.pauseOpen = true;

    setTimeout(() => {
      this.pauseOpen = false;
    }, timeout);
  }

  selectItem(index: number) {
    let item = this.suggestions[index];

    this.value = item.text;

    this.dispatchEvent(new CustomEvent('item-selected', {
      composed: true,
      bubbles: true,
      detail: {
        index,
        value: item.value,
      },
    }));

    this.open = false;

    // Make sure the focus handler doesn't insta-open our
    // suggestions again for a short while.
    this.pauseOpening();
  }

  unfocusCurrentlySelectedItem() {
    if (this.currentlyFocusedItemIndex > -1) {
      this.menu.items[this.currentlyFocusedItemIndex]?.rippleHandlers.endFocus();
    }
  }

  tryFocusItem(index: number) {
    this.unfocusCurrentlySelectedItem();

    const itemsCount = this.menu.items.length;
    if (itemsCount === 0) {
      return;
    }

    const lastIndex = itemsCount - 1;
    if (index < 0) {
      // Go to end of list
      index = lastIndex;
    }

    if (index > lastIndex) {
      // Go to start of list
      index = 0;
    }

    const item = this.menu.items[index];
    if (!item) {
      // Go back up
      this.currentlyFocusedItemIndex = 0;

      return;
    }

    item.rippleHandlers.startFocus();
    this.currentlyFocusedItemIndex = index;
  }

  onKeyUp(event: any) {
    const KEY_CODES = {
      LEFT_ARROW: 37,
      RIGHT_ARROW: 39,
      UP_ARROW: 38,
      DOWN_ARROW: 40,
      ENTER: 13,
      ESCAPE: 27
    };

    const keyCode = event.which || event.keyCode;

    switch (keyCode) {
      case KEY_CODES.DOWN_ARROW:
        this.tryFocusItem(this.currentlyFocusedItemIndex + 1);
        break;
      case KEY_CODES.UP_ARROW:
        this.tryFocusItem(this.currentlyFocusedItemIndex - 1);
        break;
      case KEY_CODES.ENTER:
        if (this.open && this.currentlyFocusedItemIndex > -1) {
          this.selectItem(this.currentlyFocusedItemIndex);
        }
        break;
    }
  }

  render() {
    const textfield = super.render();

    return html`
      ${textfield}

      ${this.hideSearch ? html`` : html`
        <mwc-menu
          tabindex="-1"
          innerRole="listbox"
          wrapFocus
          corner="BOTTOM_START"
          .open=${this.open}
          .anchor=${this.anchor}
          ?fullWidth=${!this.fixedMenuPosition}
          ?fixed=${this.fixedMenuPosition}
          @opened=${this.onOpened}
          @closed=${() => this.open = false}
          @action=${this.onSelected}
        >
          ${this.suggestions.map(suggestion => this.renderSuggestion(suggestion))}
        </mwc-menu>
      `}
    `;
  }
}
