import {FontFaceFactory, FontFactoryFactory} from "./types";

type DynamicImportReturn = { default: FontFaceFactory; };

// A factory that creates a factory
export const factory: FontFactoryFactory = (cdnUrl: string) => async (fontName: string) => {
  let fontModule: DynamicImportReturn;

  try {
    // Get font based on name
    fontModule = await import(`./${fontName}.js`);
  } catch (e) {
    console.error(`Unable to load font with name: ${fontName}.`);

    throw e;
  }

  const fontFaceFactory = fontModule.default;

  return fontFaceFactory(cdnUrl);
};

export default factory;
