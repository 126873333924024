import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';

import '@cbar/cbar-shared-styles/cbar-shared-styles.js';
import '@material/web/dialog/dialog.js';


/**
 *
 *
 * @customElement
 * @polymer
 * @demo demo/index.html
 */
class CcDuplicateProfile extends PolymerElement {
    static get template() {
        return html`
            <style include="cbar-shared-styles">
                [hidden] {
                    display: none;
                }
                [disabled]{
                    color: grey;
                }
                .buttons{
                    display: flex;
                    align-items: center;
                }
            </style>
        
            <md-dialog with-backdrop open="{{opened}}" on-close="__closeHandler">
                <div slot="headline" style="display: flex; flex-direction: column">
                    <h6>Existing Profiles Found</h6>
                    <div style="font-size: 1rem; line-height: normal">There are existing profiles that match these contact details. Select an existing profile or you can create a new profile with the duplicate information.</div>
                </div>
                <div slot="content">
                    <template is="dom-repeat" items="[[__displayedProfiles]]" as="profile">
                        <cbar-places-item class="profile-item" lines="3" extra-information="[[profile.mobile]]" imageShape="circle" imageSizing="cover" image="[[profile.image]]" title="[[profile.title]]" description="[[profile.description]]" id="[[profile.id]]" on-item-selected="__handleItemSelected" showCheckbox></cbar-places-item>
                    </template>
                    <cbar-places-item title="Create New" description="Create New Profile" icon="icons:add-circle-outline" on-item-selected="__fireNew"></cbar-places-item>
                </div>
                <div class="buttons" slot="actions">
                    <paper-spinner active="[[loading]]"></paper-spinner>
                    <template is="dom-if" if="[[!selectedItem]]">
                        <paper-button on-tap="toggle" disabled="[[loading]]">Close</paper-button>
                    </template>
                    <template is="dom-if" if="[[selectedItem]]">
                        <paper-button on-tap="__handleUse" disabled="[[loading]]">Use</paper-button>
                        <paper-button on-tap="__handleUpdate" disabled="[[loading]]">Update</paper-button>
                    </template>
                </div>
            </md-dialog>
        `;
    }

    static get is() {
        return 'cbar-duplicate-profile';
    }

    static get properties() {
        return {
            /**
             * Whether the add person dialog is opened or not
             *
             * @type {Boolean}
             */
            opened: {
                type: Boolean,
                notify: true,
                value: false,
            },
            profiles: {
                type: Array,
                value: []
            },
            loading: {
                type: Boolean,
                value: false
            },
            selectedItem : {
                type: Object,
                value: false,
            }
        };
    }

    static get observers() {
        return [
            '_handleProfiles(profiles.length)',
        ];
    }

    get buttonsDiv(){
        if(!this.__buttonsDiv){
            this.__buttonsDiv = this.root.querySelector('div.buttons');
        }
        return this.__buttonsDiv;
    }

    get checkedItems(){
        let selectedItems = [];

        this.items.forEach((item) => {
            if(item.checkboxChecked){
                selectedItems.push(item);
            }
        });

        return selectedItems;
    }

    get items(){
        return this.root.querySelectorAll('.profile-item');
    }

    toggle(){
        this.opened = !this.opened;
    }

    _handleProfiles(length){
        if(length === 0){
            return;
        }

        let profilesToDisplay = [];
        this.profiles.forEach(profile => {
            profilesToDisplay.push({
                id: profile.id,
                title: `${profile.firstname} ${profile.lastname}`,
                description: profile.email,
                mobile: profile.mobile,
                image: profile.image
            })
        });

        this.__displayedProfiles = profilesToDisplay;
    }

    __fireNew(e){
        this.loading = true;
        this.dispatchEvent(new CustomEvent('new-clicked',{
            bubbles: true,
            composed: true
        }));
    }

    __handleItemSelected(e){
        e.target.checkboxChecked = !e.target.checkboxChecked;
        this.items.forEach(child => {
            if(child !== e.target){
                child.checkboxChecked = false;
            }
        });

        this.__handleCheckboxChecked(e, e.target.checkboxChecked);
    }

    __handleCheckboxChecked(e, selected){
        this.selectedItem = selected ? e.detail.item : false;
    }

    __handleUpdate(e){
        this.loading = true;
        this.__handleSelectedItem(true)
    }

    __handleUse(e){
        this.loading = true;
        this.__handleSelectedItem();
    }

    __handleSelectedItem(updating = false){
        this.dispatchEvent(new CustomEvent('existing-clicked', {
            bubbles: true,
            composed: true,
            detail: {
                item: this.selectedItem,
                updating
            }
        }));
    }

    reset() {
        this.items.forEach(child => {
            child.checkboxChecked = false;
        });

        this.selectedItem = false;
    }

    __closeHandler(e){
        if(this.loading){
            e.preventDefault();
        } else {
            this.opened = false
        }
    }

}

window.customElements.define(CcDuplicateProfile.is, CcDuplicateProfile);
