import {html, LitElement, PropertyValues} from 'lit';
import {property} from 'lit/decorators.js';
import {CbarThemeableMixin} from "@cbar/cbar-themeable-mixin";
import {DomModule} from "@polymer/polymer/lib/elements/dom-module.js";
import '@vaadin/vaadin-combo-box/theme/material/vaadin-combo-box.js';


export type ThemeContent = Element & {
  content?: any;
};

type ComboBox = Element & {
  renderer: Function,
  selectedItem: ExpectedItem | Boolean,
  items: Array<ExpectedItem>
}

export type ExpectedItem = {
  title?: string,
  id?: string | number,
  image?: string,
  description?: string
}

const themeTemplate: ThemeContent | null = DomModule.import('cbar-shared-styles', 'template');
// @ts-ignore
const sharedThemeElement = themeTemplate?.content?.firstElementChild;

if (!sharedThemeElement) {
  console.warn('The theme template or its first element child is missing.');
}

// @ts-ignore
const sharedTheme = sharedThemeElement?.textContent ?? '';


export class CbarEntitySelect extends CbarThemeableMixin(LitElement) {

  @property({type: Array}) items = [];
  @property({type: Array}) _items = [];
  @property({type: String}) label = "Select";
  @property({type: String}) placeholder = "Select";
  @property({type: String}) value = "";
  @property({type: Object}) selected = {};
  @property({type: Boolean}) _removingFromAttribute = false;

  render() {
    return html`
      <style>
        ${sharedTheme}
        vaadin-combo-box {
          width: 100%;
          --material-body-text-color: var(--cbar-entity-select-color, black);
          --material-primary-text-color: var(--cbar-entity-select-color, black);
          --material-primary-color: var(--cbar-entity-select-color, black);
          --material-secondary-text-color: var(--cbar-entity-select-color, black);
          --_material-text-field-input-line-background-color: var(--cbar-entity-select-color, black);
          --vaadin-input-field-placeholder-color: var(--cbar-entity-select-color, black) !important;
          --vaadin-combo-box-overlay-width: 300px !important;
          --material-disabled-text-color: var(--cbar-entity-select-color, black);
        }
      </style>

      <vaadin-combo-box label="${this.label}" @selected-item-changed="${(e: any) => {this.handleSelectedItem(e)}}" @click="${(e: any) => {e.target.$.input.inputElement.select()}}" item-label-path="title" item-id-path="id" item-value-path="id" placeholder="${this.placeholder}"></vaadin-combo-box>
    `;
  }

  updated(updatedProps: PropertyValues) {
    super.updated(updatedProps);

    if (updatedProps.has('items')) {
      this.handleUpdatedItems()
    }

    if (updatedProps.has('value')) {
      this.handleUpdatedValue()
    }
  }

  constructor() {
    super();


    requestAnimationFrame(() => {
      if (this.dropdown) {
        this.dropdown.renderer = (root: any, comboBox: any, model: any) => {
          root.innerHTML = `
                <style>
                    :host{
                        --_material-item-selected-icon-display: none !important;
                    }
                </style>
                <div style="display: flex; align-items: center" class="testing-item">
                     <div style="display: flex">
                        ${model.item.image ? `<img src=${model.item.image} style="width: 24px; height: 24px; margin-right: 8px; border-radius: 50%">` : `<div style="width: 24px; height: 24px; margin-right: 8px; border-radius: 50%"></div>`}
                     </div>
                   <div>
                     <div style="line-height: 16px">${model.item.title}</div>
                     ${model.item.description ? `<div style="color: grey; line-height: 16px">${model.item.description}</div>` : ``}
                   </div>
                </div>`
        };
      }
    });
  }


  get dropdown(): ComboBox | null | undefined {
    return this?.shadowRoot?.querySelector('vaadin-combo-box');
  }

  handleSelectedItem(e: any) {
    this.dispatchEvent(new CustomEvent('selected-item-changed', {
      bubbles: true,
      composed: true,
      detail: {
        value: e.detail.value
      }
    }))

    this.selected = e.detail.value;
  }

  handleUpdatedValue() {
    if (this.dropdown) {
      let filteredItem = this._items.find((item: ExpectedItem) => item.id == this.value);

      if (filteredItem) {
        this.dropdown.selectedItem = filteredItem;
      } else {
        this.dropdown.selectedItem = false;
      }
    }
  }

  handleUpdatedItems() {
    // this should allow hiding of the items display in the devtools display
    if (!this._removingFromAttribute) {
      this._items = this.items;
    } else {
      this._removingFromAttribute = false;
      return;
    }

    if (this.dropdown) {
      this.dropdown.items = this._items;
    }

    this._removingFromAttribute = true;
    this.removeAttribute('items');
  }
}
