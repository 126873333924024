import {LitElement} from 'lit';
import {property} from "lit/decorators.js";
import {getConfig} from "@cbar/cbar-config";

type Constructor<T = {}> = new (...args: any[]) => T;

export const CbarThemeableMixin =
  <T extends Constructor<LitElement>>(superClass: T) => {
    class ThemeableElement extends superClass {
      @property({type: String, reflect: true})
      theme?: string;

      constructor(...rest: any) {
        super(rest);

        if (!this.theme) {
          this.loadTheme();
        }

        // Listen for theme changes in the config
        window.addEventListener('cbar-app-theme-changed', (e: any) => {
          this.theme = e.detail;
        });
      }

      loadTheme() {
        const config = getConfig();
        if (!config) {
          return;
        }

        this.theme = config.config.theme || 'md';
      }
    }

    return ThemeableElement as T;
  };
