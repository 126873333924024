// @ts-ignore
import { formatAddress } from "localized-address-format/dist/index.esm.js";
import {countries} from "./available-countries";

export type Address = {
  type: String;
  title?: String;
  streetAddress1: String;
  streetAddress2?: String;
  streetAddress3?: String;
  suburb: String;
  state: String;
  country?: String;
  countryCode?: String;
  postcode: Number;
};

export const format = (address: Address, multiLine = false, addCountry = true) => {
  // the library requires an ISO code for the library to display correctly, if we have one, use the library
  if(address.countryCode || address.country){
    // we need to check here what missing information we need to fetch here
    address.country = fetchValidCountry(address, addCountry);
    address.countryCode = fetchCountryCode(address);

    if(!!address.countryCode && checkValidCountryCode(address.countryCode)) {
      return formatFromLib(address, multiLine, (!!address.country && addCountry))
    }
  }

  // if not use our existing local function
  return formatFromLocal(address, multiLine)
}

const formatFromLib = (address: Address, multiLine = false, addCountry = true) => {
  let addressLines: string[] = [];

  [address.title, address.streetAddress1, address.streetAddress2, address.streetAddress3].forEach((value) => {
    if(value){
      addressLines.push(value as string)
    }
  })

  let formattedAddress = formatAddress({
    postalCountry: address.countryCode as string,
    postalCode: address.postcode.toString(),
    addressLines: addressLines,
    administrativeArea: address.state as string,
    locality: address.suburb as string
  }).join(multiLine ? "\n" : " ");

  if(addCountry){
    formattedAddress = [formattedAddress, address.country].join(multiLine ? "\n" : " ")
  }

  return formattedAddress;
}

const formatFromLocal = (address: Address, multiLine = false) => {
  let returnString = '';
  // all of the possible params and their order
  const allParams = [
    'title',
    'streetAddress1',
    'streetAddress2',
    'streetAddress3',
    ['suburb', 'state', 'postcode'],
    'country',
  ];

  // loop through each
  allParams.forEach((param: any, i: number) => {
    type ObjectKey = keyof typeof address;
    const formatParam = param as ObjectKey;
    const sectionSeperator = multiLine ? '\n' : ", "

    if (Array.isArray(param)) {
      let joinedArray = i !== 0 ? sectionSeperator : '';
      param.forEach((prop, i) => {
        // @ts-ignore
        joinedArray += `${address[prop]}${i === param.length ? ", " : " "}`;
      });

      // @ts-ignore
      returnString += joinedArray;
      return;
    }

    // if the param is set, then add it to the address
    if (address[formatParam]) {
      // if the previous param is set (checking for end of string) add a comma
      if (i !== 0) {
        returnString += sectionSeperator;
      }

      // add the section
      returnString += address[formatParam];
    }
  });

  return returnString;
}

const fetchValidCountry = (address: Address, required = true) : String | undefined => {
  if(address.country){
    return address.country
  }

  if(required){
    // @ts-ignore
    let foundCountryName = countries[address.countryCode];

    if(!foundCountryName){
      return address.countryCode
    }

    return foundCountryName;
  }

  return undefined;
}

const fetchCountryCode = (address: Address) : String | undefined => {
  // @ts-ignore
  return !!address.countryCode ? address.countryCode : Object.keys(countries).find(key => countries[key] === address.country);
}

const checkValidCountryCode = (code: String) : Boolean => {
  // @ts-ignore
  return !!countries[code]
}
