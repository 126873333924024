export class PromiseLater {
  private promise;
  public then;
  public catch;

  constructor() {
    this.promise = new Promise((res, rej) => {
      this.resolve = res;
      this.reject = rej;
    });

    this.then = this.promise.then.bind(this.promise);
    this.catch = this.promise.catch.bind(this.promise);
  }

  resolve(value?: any) {}
  reject(value?: any) {}
}
