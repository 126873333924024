import { html, LitElement } from 'lit';
import { property } from 'lit/decorators.js';
import { CbarThemeableMixin } from '@cbar/cbar-themeable-mixin';
import { repeat } from 'lit/directives/repeat.js';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js/max';
import { Address } from '@cbar/cbar-address-format';
import {AvailableFont, injectFontFace} from '@cbar/cbar-fonts';
import {DomModule} from '@polymer/polymer/lib/elements/dom-module.js';

import '@material/mwc-dialog/mwc-dialog.js';
import '@material/mwc-button/mwc-button.js';
import '@material/mwc-icon-button/mwc-icon-button';

import '@cbar/cbar-contact-card/cbar-contact-card.js';
import '@cbar/cbar-places-list/cbar-places-item.js';

import '@polymer/paper-listbox/paper-listbox.js';

import '@polymer/paper-menu-button/paper-menu-button.js';
import '@polymer/paper-item/paper-icon-item.js';

const themeTemplate = DomModule.import('cbar-shared-styles', 'template');
// @ts-ignore
const sharedThemeElement = themeTemplate?.content?.firstElementChild;

if (!sharedThemeElement) {
  console.warn('The theme template or its first element child is missing.');
}

// @ts-ignore
const sharedTheme = sharedThemeElement?.textContent ?? '';

type Email = {
  address: String;
  type: String;
};

type PhoneNumber = {
  type: String;
  number: String;
  country?: String;
};

interface ListItem extends Element{
  unclickable?: boolean
};

type Person = {
  id: String | Number;
  firstName?: String;
  lastName?: String;
  title?: String;
  image?: String;
  description?: String;
  extraInformation?: String;
  addresses?: Address[];
  emails?: Email[];
  phoneNumbers?: PhoneNumber[];
  meta?: String;
  editUrl?: String;
  connectDisabled?: Boolean;
  connectSelf?: Boolean;
};

type ActionType = 'url' | 'page' | 'matrix';

type Action = {
  type: ActionType;
  title: String;
  url?: String;
  icon?: String;
  primary?: Boolean
};

export class CbarContactsList extends CbarThemeableMixin(LitElement) {
  @property({ type: Array }) list = [];

  @property({ type: Array }) sortedList = [];

  @property({ type: Array }) selectedProfile: Person = {
    id: 0,
    title: '',
    image: '',
    description: '',
    extraInformation: '',
    addresses: [],
    emails: [],
    phoneNumbers: [],
    meta: '',
  };

  @property({ type: String }) sortingProp = '';

  @property({ type: Boolean }) addressMulti = true;

  @property({ type: Boolean }) editInWindow = false;

  @property({ type: Boolean }) showConnect = false;

  @property({ type: Boolean }) showView = false;

  @property({ type: String }) connectButtonWording = 'Connect';

  @property({ type: String }) selfConnectWording = 'Me';

  @property({ type: Array }) actions: Action[] = [];

  @property({ type: Boolean }) hideMeta = false;

  @property({ type: Boolean }) disablePrimary = false;

  constructor() {
    super();

    // Include the material icons font
    injectFontFace(AvailableFont.materialIcons);
  }

  get listDiv() {
    return this.shadowRoot ? this.shadowRoot.querySelector('.list-div') : null;
  }

  get viewDialog() {
    return this.shadowRoot
      ? this.shadowRoot.querySelector('cbar-contact-card')
      : null;
  }

  get contactItems(){
    return this.shadowRoot?.querySelectorAll('cbar-places-item');
  }

  updated(changedProperties: any) {
    if (changedProperties.has('list')) {
      this.handleListChanged();
    }

    if (changedProperties.has('sortingProp')) {
      this.handleResorting();
    }

    if (changedProperties.has('actions')) {
      this.handleActionschanged();
    }
  }

  render() {
    // @ts-ignore
    return html`
      <style>
        ${sharedTheme}
        mwc-button {
          --mdc-theme-primary: var(
            --cbar-sys-color-secondary,
            var(--cbar-dark-primary-color, #003f83)
          );
          --mdc-theme-on-primary: white;
        }
      </style>
      <div class="list-div">
        ${repeat(
          this.sortedList,
          (listedPerson: Person) => listedPerson.id,
          listedPerson => this.renderPersonListItem(listedPerson)
        )}
      </div>

      <cbar-contact-card
        .profile="${this.selectedProfile}"
        .addressMulti="${this.addressMulti}"
        .editInWindow="${this.editInWindow}"
      ></cbar-contact-card>
    `;
  }

  renderPersonListItem = (entry: Person) => html`
    <cbar-places-item
      title="${this.__getUsersTitle(entry)}"
      imageShape="circle"
      imageSizing="cover"
      image="${entry.image}"
      description="${entry.description}"
      ?unclickable="${this.disablePrimary}"
      meta="${this.hideMeta ? '' : entry.meta}"
      extra-information="${entry.extraInformation}"
      id="${entry.id}"
      @item-selected="${(e: any) => {
        this.handleUserTapped(e);
      }}"
    >
      ${this.actions.length > 1
        ? this.actionMenu(this.actions, entry)
        : this.actionDiv(this.actions[0], entry)}
    </cbar-places-item>
  `;

  actionMenu = (actions: Action[], entry: Person) => html`
    <paper-menu-button
      horizontal-align="right"
      vertical-offset="50"
      style="padding: 0"
      ignore-select
      no-animations
    >
      <mwc-icon-button
        icon="more_vert"
        slot="dropdown-trigger"
      ></mwc-icon-button>
      <div slot="dropdown-content">
        <paper-listbox
          attr-for-selected="name"
          @iron-select="${(e: any) => {
            this.handleActionSelection(e, entry);
          }}"
        >
          ${actions.map(action => this.actionMenuItem(action, entry))}
        </paper-listbox>
      </div>
    </paper-menu-button>
  `;

  actionDiv = (action: Action, entry: Person) =>
    this.calculateVisibleItem(action, entry)
      ? html`
          <div>
            <paper-icon-button
              @click="${() => {
                this.handleAction(action, entry);
              }}"
              icon="${action.icon}"
              title="${action.title}"
            ></paper-icon-button>
          </div>
        `
      : html``;

  actionMenuItem = (action: Action, entry: Person) =>
    !!action && this.calculateVisibleItem(action, entry)
      ? html`
          <paper-icon-item } name="${action.title}"">
            <mwc-icon slot="item-icon">${action.icon}</mwc-icon>
            ${action.title}
          </paper-icon-item>
        `
      : html``;

  calculateVisibleItem = (action: Action, entry: Person) => {
    if(!action){
      return false;
    }

    if (action.type === 'matrix') {
      return this.showConnect && !entry.connectDisabled;
    }

    return true;
  };

  handleListChanged = () => {
    // @ts-ignore
    this.sortedList = this.sortList(this.list);
    this.requestUpdate();
  };

  __getUsersTitle = (user: Person) =>
    user.title ? user.title : `${user.firstName} ${user.lastName}`;

  sortList = (list: Array<Person>) => {
    const { sortingProp } = this;

    if (!sortingProp) {
      return list;
    }
    // @ts-ignore
    return list.sort((a, b) => (a[sortingProp] > b[sortingProp] ? 1 : -1));
  };

  // if the sorting has changed then we want the list to regenerate
  handleResorting = () => {
    this.handleListChanged();
  };

  handleUserTapped = (e: any) => {
    const element = e.detail.item;

    if (!this.viewDialog || this.disablePrimary) {
      return;
    }

    const listOfMembers: Person[] = this.list;
    // eslint-disable-next-line eqeqeq
    const foundUser = listOfMembers.find(user => user.id == element.id);

    if (foundUser) {
      if (foundUser.phoneNumbers) {
        const formattedNumbers: Array<PhoneNumber> = [];
        foundUser.phoneNumbers.forEach(number => {
          formattedNumbers.push(this.__getFormattedPhoneNumber(number));
        });

        foundUser.phoneNumbers = formattedNumbers;
      }

      this.selectedProfile = {
        ...foundUser,
        title: this.__getUsersTitle(foundUser),
      };

      const primaryAction = this.getPrimaryAction();

      if(primaryAction){
        this.handleAction(primaryAction, this.selectedProfile);
      }else {
        // @ts-ignore
        this.viewDialog.show();
      }
    }
  };

  __getFormattedPhoneNumber = (number: PhoneNumber) => {
    let formattedNumber = number;

    // if we have a country to format the number for, we format it, if not, we just return the right value
    if (number.country) {
      const result = parsePhoneNumber(
        number.number as string,
        number.country as CountryCode
      );

      formattedNumber = {
        number: result.formatInternational(),
        type: number.type,
        country: number.country,
      };
    }

    return formattedNumber;
  };

  handleConnect = (action: Action, person: Person) => {
    this.dispatchEvent(
      new CustomEvent('contact-selected', {
        bubbles: true,
        composed: true,
        detail: {
          action,
          person,
        },
      })
    );
  };

  handleView = (action: Action, person: Person) => {
    this.dispatchEvent(
      new CustomEvent('view-selected', {
        bubbles: true,
        composed: true,
        detail: {
          action,
          person,
        },
      })
    );
  };

  getPrimaryAction = () => {
    if(!this.actions){
      return null;
    }

    return this.actions.find(action => !!action.primary);
  }

  handleActionSelection = (e: any, entry: Person) => {
    e.preventDefault();

    const listbox = e.target;
    const selectedAction = this.actions.find(
      action => action.title === listbox.selected
    );

    if (!selectedAction) {
      return;
    }

    this.handleAction(selectedAction, entry);

    requestAnimationFrame(() => {
      listbox.closest('paper-menu-button')?.close();
    });

    // reset this here so double selecting fires
    listbox.selected = '';

  };

  timeoutMouseover = () => {
    if(!this.contactItems){
      return;
    }

    this.contactItems.forEach((item : ListItem ) => {
      item.unclickable = true;

      requestAnimationFrame(() => {
        item.addEventListener('mousemove', () => {
          item.unclickable = false;
        }, {once: true})
      })
    })
  }

  handleAction = (selectedAction: Action, entry: Person) => {
    const functionMap = {
      page: this.handleView,
      matrix: this.handleConnect,
      url: this.handleUrl,
    };

    if (Object.keys(functionMap).includes(selectedAction.type)) {
      functionMap[selectedAction.type](selectedAction, entry);
    }
  };

  // dont show meta if there are actions to display
  handleActionschanged = () => {
    this.hideMeta = this.actions.length > 0;
  };

  // url type actions should simply open a url
  handleUrl = (action: Action, person: Person) => {
    this.dispatchEvent(
      new CustomEvent('url-selected', {
        bubbles: true,
        composed: true,
        detail: {
          action,
          person,
        },
      })
    );
  };
}
