import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';

import '@polymer/paper-spinner/paper-spinner.js';
import '@cbar/cbar-shared-styles/cbar-shared-styles.js';
import '@material/web/dialog/dialog.js';

/**
 *
 * @customElement
 * @polymer
 * @demo demo/index.html
 */
class CCAddToEntity extends PolymerElement {
    static get template() {
        return html`
    <style include="cbar-shared-styles">
      :host {
        display: block;

        --cbar-date-time-picker-label-buttons: {
            justify-content: flex-end;
        };
      }

      cbar-entity-relationship-selector{
          --mdc-theme-primary: var(--cbar-sys-color-primary, #004CA2);
          --mdc-text-field-fill-color: var(--cbar-sys-color-surface, #fffbfeff);
          --mdc-select-fill-color: var(--cbar-sys-color-surface, #fffbfeff);

          width: 100%;
      }
      
      [hidden] {
        display: none;
      }
    </style>

    <md-dialog with-backdrop open="{{opened}}" on-close="__closeHandler">
       <h6 slot="headline">[[profileTitle]]</h6>
        
      <cbar-entity-relationship-selector
        slot="content"  
        id="entityRelationshipSelector"
        entities="[[entities]]"
        entity-type="[[entityType]]"
        disabled$="[[loading]]"
        entity-required
        relationship-required 
        context-entity-id="[[contextEntityId]]" 
        relationships-label="Position">
      </cbar-entity-relationship-selector>

      <div slot="actions">
        <p hidden="[[!_errorMessage]]">[[_errorMessage]]</p>
        <paper-button hidden$=[[loading]] class="btn-cancel" on-tap="close">Cancel</paper-button>
        <paper-button hidden$=[[loading]] class="btn-assign" on-tap="assign">Assign</paper-button>

        <paper-spinner active="[[loading]]" hidden$="[[!loading]]"></paper-spinner>
      </div>
    </md-dialog>
`;
    }

    static get is() {
        return 'cc-add-to-entity';
    }

    static get properties() {
        return {
            /**
             * Whether the add person dialog is opened or not
             *
             * @type {Boolean}
             */
            opened: {
                type: Boolean,
                notify: true,
                value: false,
            },

            /**
             * A map of entityType => entities array, where each entity is a
             * CBAR entity object
             *
             * @type {Object}
             */
            entities: Object,

            /**
             * The type of entity that the user is getting added to.
             *
             * Either "Group" or "Event"
             */
            entityType: String,

            contextEntityId : String,

            profileTitle: String,

            /**
             * The profile ID of the user the role is going to be added to
             */
            profileId: Number,

            /**
             * Whether the form is currently loading.
             *
             * Adds a spinner and hides cancel & submit buttons if true.
             *
             * @type {Boolean}
             */
            loading: {
                type: Boolean,
                value: false,
            },
        };
    }

    ready(){
        super.ready();

        // new dialog has an issue with some dropdowns, we need to handle this here
        requestAnimationFrame(() => {
            this.handleOverflowIssue();
        });
    }

    get dialog(){
        if(!this._dialog){
            this._dialog = this.root.querySelector('md-dialog')
        }
        return this._dialog
    }

    open() {
        this.opened = true;
    }

    close() {
        this.opened = false;
    }

    assign() {
        let entityRelationshipEle = this.$.entityRelationshipSelector;

        if( !entityRelationshipEle.validate() ) {
            return;
        }

        this._errorMessage = null;
        this.loading = true;

        // Prepare data for AJAX submission
        let data = {
            action: 'add',
            source_entity: 'Profile',
            source_entity_id: this.profileId,
            target_entity: this.entityType,
            target_entity_id: entityRelationshipEle.selectedEntity.id,
            quota: entityRelationshipEle.selectedRelationship.id,
        };

        let {selectedStartDate, selectedEndDate} = entityRelationshipEle;

        if( selectedStartDate ) {
            // entity-roles expects the dates in d-m-y format...
            data.start_time = moment(selectedStartDate).format('DD-MM-YYYY HH:mm');
        }

        if( selectedEndDate ) {
            data.end_time = moment(selectedEndDate).format('DD-MM-YYYY HH:mm');
        }

        return new Promise((res, rej) => {
            $.ajax({
                url: '/ajax/entity-roles',
                type: 'POST',
                dataType: 'json',
                data,
                success: data => {
                    this.loading = false;

                    if( data.error ) {
                        return rej(data.message);
                    }

                    this.dispatchEvent(new CustomEvent('role-added', {
                        bubbles: true,
                        composed: true,
                        detail: data,
                        cancelable: true
                    }));

                    return res(data);
                },
                error: xhr => {
                    this.loading = false;
                    this._errorMessage = xhr.responseJSON ? xhr.responseJSON : xhr.responseText;

                    rej(this._errorMessage);
                }
            });
        })
    }

    __closeHandler(e){
        if(this.loading){
            e.preventDefault();
        } else {
            this.opened = false;
        }
    }

    handleOverflowIssue(){
        this.dialog.container.style.overflow = "visible";
        this.dialog.scroller.style.overflow = "visible";
    }
}

window.customElements.define(CCAddToEntity.is, CCAddToEntity);
