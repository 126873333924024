import {css, PropertyValues} from 'lit';
import {property} from 'lit/decorators.js';
import {TextField} from '@material/mwc-textfield';

import {applyDenseStylesheet, disableDenseStylesheet} from "./dense-fix";

export class CbarTextfield extends TextField {
  static styles = [
    ...TextField.styles,
    css`
      :host([hidden]) {
        display: none;
      }

      mwc-textfield {
        width: 100%;
      }
    `
  ];

  @property({type: Boolean})
  dense = false;

  @property({type: Boolean})
  hideErrorsOnFocus = false;

  constructor() {
    super();

    // Recalculate layout when the textfield becomes visible.
    // Fixes https://github.com/material-components/material-web/issues/2915
    this.respondToVisibility();
  }

  public updated(changedProperties: PropertyValues) {
    super.updated(changedProperties);

    if (changedProperties.has('dense')) {
      requestAnimationFrame(() => this.dense? applyDenseStylesheet(this) : disableDenseStylesheet(this));
    }
  }

  protected onInputFocus() {
    super.onInputFocus();

    if (this.hideErrorsOnFocus) {
      // @ts-ignore
      this.mdcFoundation.styleValidity(true);
    }
  }

  respondToVisibility() {
    const options = {
      root: document.body,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Re-calculate layout to ensure label on outlined textfield
          // is properly displayed
          this.layout();
        }
      });
    }, options);

    observer.observe(this);
  }
}
