export interface CbarWindow extends Window {
  CBAR_APP_CONFIG: CbarAppConfig;
}

type CbarAppConfig = {
  theme?: 'md' | string;
}

const Cbar = (window as any as CbarWindow);

export class Config {
  public config: CbarAppConfig;

  constructor(config: CbarAppConfig = {}) {
    this.config = config;
  }

  setTheme(theme: 'md' | string) {
    this.config.theme = theme;

    window.dispatchEvent(new CustomEvent('cbar-app-theme-changed', {
      detail: theme,
    }));
  }
}

let config: Config | null = null;

export function getConfig() {
  if (!config && typeof Cbar !== "undefined") {
    config = new Config(Cbar.CBAR_APP_CONFIG);
  }

  return config;
}
