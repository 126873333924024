import {registerTranslateConfig, translate, use, get, Translation} from 'lit-translate';
import {AvailableLanguages} from '@cbar/cbar-localizations/translations.js';
import {Constructor} from "@lit/reactive-element/decorators/base";
import {PropertyValues} from "@lit/reactive-element";
import {DirectiveResult} from 'lit/directive.js';
import {property} from 'lit/decorators.js';
import {LitElement} from "lit";

export declare class LitLocalizeInterface {
  public locale: string;

  localize(str: string): DirectiveResult;

  loadLocales(path: string): Promise<any>;

  getTranslation(str : string): string;
}

let globalLocale = '';

export const LitLocalize = <T extends Constructor<LitElement>>(superClass: T) => {
  class LocalizeBaseClass extends superClass {
    @property()
    public locale: string = globalLocale;

    constructor(...args: any[]) {
      super(...args);

      this.loadLocales();

      registerTranslateConfig({
        empty: (key: string) => key,
      });
    }

    updated(changedProperties: PropertyValues) {
      if (changedProperties.has('locale')) {
        if(AvailableLanguages.indexOf(this.locale) === -1){
          return;
        }

        globalLocale = this.locale;
        use(this.locale);
      }

      super.updated?.(changedProperties);
    }

    localize(str: string) {
      if (!str) {
        return '';
      }

      return translate(str);
    }

    getTranslation(str : string) : Translation{
      if(!str){
        return '';
      }

      return get(str);
    }

    async loadLocales() {
      registerTranslateConfig({
        empty: (key: string) => key,
        loader: async lang => {
          let res;

          // TODO: THIS IS DUE TO AN ISSUE IN PASSPORT REQUIRING NON-DYNAMIC IMPORTS
          switch (lang) {
            case "de":
              // @ts-ignore
              res = await import(`@cbar/cbar-localizations/lngs/de.js`);
              break;
            case "en":
              // @ts-ignore
              res = await import(`@cbar/cbar-localizations/lngs/en.js`);
              break;
            case "es":
              // @ts-ignore
              res = await import(`@cbar/cbar-localizations/lngs/es.js`);
              break;
            case "fr":
              // @ts-ignore
              res = await import(`@cbar/cbar-localizations/lngs/fr.js`);
              break;
            case "pt":
              // @ts-ignore
              res = await import(`@cbar/cbar-localizations/lngs/pt.js`);
              break;
            case "sr":
              // @ts-ignore
              res = await import(`@cbar/cbar-localizations/lngs/sr.js`);
              break;
            default:
              // @ts-ignore
              res = await import(`@cbar/cbar-localizations/lngs/en.js`);
              break;
          }

          return res.default
        }
      });

      if (globalLocale) {
        // Already set, set it on this instance.
        this.locale = globalLocale;
      }

      if (!this.locale) {
        this.locale = this._getLanguageFromBrowser();
      }
    }

    _getLanguageFromBrowser() {
      // Match the browser language to something in our locales
      const navigatorLanguage = navigator.language.toLowerCase();
      if (AvailableLanguages.indexOf(navigatorLanguage) > -1) {
        // easy
        return navigatorLanguage;
      }

      // Otherwise, check if we have a match for any of
      // the languages supplied in the navigator.languages array
      // IE11 does not have navigator.languages
      const navigatorLanguages = navigator.languages || [navigator.language];
      for (let language of navigatorLanguages) {
        language = language.toLowerCase();

        if (AvailableLanguages.indexOf(language) > -1) {
          return language;
        }
      }

      return 'en';
    }
  }

  return LocalizeBaseClass as Constructor<LitLocalizeInterface> & T;
}
