import {css, html, LitElement} from 'lit';
import {repeat} from "lit/directives/repeat.js";
import {property} from 'lit/decorators.js';
import {CbarThemeableMixin} from "@cbar/cbar-themeable-mixin";
import {Address, format} from "@cbar/cbar-address-format";

import '@material/mwc-dialog/mwc-dialog.js';
import '@material/mwc-button/mwc-button.js';
import {AvailableFont, injectFontFace} from '@cbar/cbar-fonts';

type Email = {
  address: String;
  type: String;
};


type PhoneNumber = {
  type: String;
  number: String;
  country?: String;
};

type Person = {
  id: String | Number;
  firstName?: String;
  lastName?: String;
  title?: String;
  image?: String;
  description?: String;
  extraInformation?: String;
  addresses?: Address[];
  emails?: Email[];
  phoneNumbers?: PhoneNumber[];
  meta?: String;
  editUrl?: String;
};

export class CbarContactCard extends CbarThemeableMixin(LitElement) {
  static override styles = css`
   .user-img {
      height: 64px;
      width: 64px;
      min-width: 64px;
      min-height: 64px;
      border: 3px solid #eeeeee;
      border-radius: 50%;
      overflow: hidden;
    }
     a {
      text-decoration: none;
      color: var(--cbar-sys-color-secondary, var(--cbar-dark-primary-color, #003F83));
    }
    mwc-dialog {
      --mdc-dialog-max-width: 600px;
      --mdc-dialog-min-width: 50vw;
    }
    @media (max-width: 700px) {
      mwc-dialog {
        --mdc-dialog-min-width: 100vw;
      }
    }
    .information {
      margin-top: 16px;
    }
    mwc-button {
      --mdc-theme-primary: var(--cbar-sys-color-primary, #004ca2);
    }
    h4 {
      margin: 0;
    }
  `;

  @property({type: Object}) profile: Person = {
    id: 0,
    title: '',
    image: '',
    description: '',
    extraInformation: '',
    addresses: [],
    emails: [],
    phoneNumbers: [],
    meta: '',
  };

  @property({type: Boolean}) addressMulti = false;

  @property({type: Boolean}) editInWindow = false;

  render() {
    return html`
      <mwc-dialog id="dialog">
        <div style="display: flex; align-items: center; margin-bottom: 24px">
          <img class="user-img" src="${this.profile.image}" alt=""/>
          <div style="margin-left: 16px; flex-direction: column">
            <h3 style="margin:0">${this.profile.title}</h3>
            <div>${this.profile.description}</div>
          </div>
        </div>

        ${this.profile &&
        this.profile.emails &&
        this.profile.emails.length > 0
          ? html`
            <div class="information">
              ${repeat(
                this.profile.emails,
                (email: Email) => email.address,
                (email: Email) =>
                  html`
                    <div style="margin-top: 8px; display: flex;">
                      <div style="padding-right:16px;">
                        <mwc-icon>mail</iron-icon>
                      </div>
                      <div style="padding-right:8px; "><a href="mailto:${email.address}">${email.address}</a></div>
                      <div style="font-size: 12px">${email.type}</div>
                    </div>`
              )}
            </div>
          `
          : html``}
        ${this.profile &&
        this.profile.addresses &&
        this.profile.addresses.length > 0
          ? html`
            <div class="information">
              ${repeat(
                this.profile.addresses,
                (address: Address) => address,
                (address: Address) =>
                  html`
                    <div style="margin-top: 8px; display: flex;">
                      <div style="padding-right:16px;">
                        <mwc-icon>home</mwc-icon>
                      </div>
                      <div style="white-space: pre-line; padding-right:8px; display:flex;">
                        ${format(address, this.addressMulti)}
                      </div>
                      <div style="font-size: 12px">${address.type}</div>
                    </div>`
              )}
            </div>
          `
          : html``}
        ${this.profile &&
        this.profile.phoneNumbers &&
        this.profile.phoneNumbers.length > 0
          ? html`
            <div class="information">
              ${repeat(
                this.profile.phoneNumbers,
                (number: PhoneNumber) => number.number,
                number =>
                  html`
                    <div style="margin-top: 8px; display: flex;">
                      <div style="padding-right:16px;">
                        <mwc-icon>phone</mwc-icon>
                      </div>
                      <div style="padding-right:8px; ">
                        <a href="tel:${number.number}">${number.number}</a>
                      </div>
                      <div style="font-size: 12px">${number.type}</div>
                    </div>`
              )}
            </div>
          `
          : html``}

        ${this.profile.editUrl ? html`
          <!-- eslint-disable-next-line no-unused-expressions -->
          <mwc-button slot="secondaryAction" @click="${() => {this.handleSecondaryAction()}}">Edit</mwc-button>
        ` : html``}
        <mwc-button slot="primaryAction" dialogAction="close">Close</mwc-button>
      </mwc-dialog>
    `;
  }

  constructor() {
    super();

    // Include the material icons font
    injectFontFace(AvailableFont.materialIcons);
  }

  get viewDialog() {
    return this.shadowRoot ? this.shadowRoot.querySelector('mwc-dialog') : null;
  }

  show = () => {
    if(!this.viewDialog){
      return;
    }

    this.viewDialog.show();
  }

  handleSecondaryAction = () => {
    if(this.editInWindow){
      window.open(this.profile.editUrl as string);
    } else {
      window.location.href = this.profile.editUrl as string;
    }
  }
}
